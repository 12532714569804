import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import chargifyUI from "../images/chargify-ui.png";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Process"
      />

      <section className="container mx-auto p-6 mt-40 mb-20">
        <a href="/" className="font-accent antialiased italic inline-block bg-green-800 text-white text-sm py-2 px-6 rounded-full mb-12">go back home</a>
        <h1 className="font-heading antialiased uppercase text-white text-6xl leading-none mb-6 w-1/2">
          Mostly Work <span className="font-accent text-accent-100 antialiased">&</span> <br />A Little Bit Of Play
        </h1>
      </section>

      <section className="container mx-auto flex justify-between items-center p-6 mb-20">
        <div className="flex-1">

          <p className="font-accent antialiased leading-snug text-accent-100 text-md mb-2">Featured</p>

          <p className="font-heading antialiased leading-snug text-white font-medium text-3xl mb-6 pr-20">
            Redesigning the subscription management experience
          </p>

          <p className="leading-relaxed text-gray-200 bg-green-400 p-6 mr-4">
            Even when software powers tens thousands of merchants and billions in transactions each year doesn’t mean it doesn't have any challenges. Here’s how we took a step toward making Chargify relationship-focused for our customers.
          </p>

          <p className ="mt-10 font-accent italic text-sm text-white">
            <a href="/work/chargify" className="inline-block bg-green-800 text-white py-4 px-8 rounded-full">view case study</a>
          </p>
        </div>
        <div className="flex-1 overflow-x-hidden -mr-6">
          <img
            alt="Cat and human sitting on a couch"
            className="block rounded"
            src={chargifyUI}
          />
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
